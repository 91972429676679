import { Typography } from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";

const textHeader = {
  marginBottom: "20px",
};

const ParagraphTitle = ({ text, titleSmall }) => {
  return (
    <Typography variant={titleSmall ? "h6" : "h5"} style={textHeader}>
      {text}
    </Typography>
  );
};

ParagraphTitle.propTypes = {
  text: PropTypes.string.isRequired,
  titleSmall: PropTypes.bool,
};
ParagraphTitle.defaultProps = {
  titleSmall: false,
};
export default ParagraphTitle;
