import * as React from "react";
import PropTypes from "prop-types";
import theme from "../theme";

const codeContainer = {
  width: "100%",
  background: theme.palette.black.paleSilver,
  marginBottom: "20px",
  whiteSpace: "pre-wrap !important",
};
const json = {
  fontFamily: "'Inconsolata','Courier New',Courier,monospace",
  fontSize: ".875rem",
  padding: "10px 6px",
  wordWrap: "break-word",
  overflowX: "auto",
  whiteSpace: "pre-wrap",
};

const JsonContainer = ({ text }) => {
  return (
    <div style={codeContainer}>
      <pre id="json" style={json}>
        {JSON.stringify(text, null, 2)}
      </pre>
    </div>
  );
};
JsonContainer.propTypes = {
  text: PropTypes.object.isRequired,
};
export default JsonContainer;
