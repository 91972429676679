import { Typography } from "@mui/material";
import React, { useState } from "react";
import VerticalMenu from "../../components/VerticalMenu";
import HorizontalMenu from "../../components/HorizontalMenu";
import Container from "@mui/material/Container";
import { deleteResponseJsonExample } from "../../constants";
import CodeContainer from "../../components/CodeContainer";
import Helmet from "react-helmet";
import favicon from "../../images/favicon.ico";
import JsonContainer from "../../components/JsonContainer";
import { codeFont, pageStyles, pageContent, body } from "../../styleConstants";
import VersionSwitch from "../../components/VersionSwitch";
import ParagraphTitle from "../../components/PageComponents/ParagraphTitle";
import PageTitle from "../../components/PageComponents/PageTitle";

const IndexPage = () => {
  const [index, setIndex] = useState(1);

  const handleIndexChange = (newIndex) => {
    setIndex(newIndex);
  };
  return (
    <main style={pageStyles}>
      <HorizontalMenu />
      <div style={pageContent}>
        <VerticalMenu page={8} />

        <Container maxWidth="md" style={{ wordWrap: "break-word" }}>
          <PageTitle text="Cancel Order" />

          <VersionSwitch handleIndexChange={handleIndexChange} index={index} />

          <Typography variant="body1" style={body}>
            Cancel a order that is still in the{" "}
            <code style={codeFont}>reviewing</code> status by specifying the
            order <code style={codeFont}>id</code>. The cancel request will fail
            on any orders that are no longer in
            <code style={codeFont}>reviewing</code> status.
          </Typography>
          {index === 0 ? (
            <CodeContainer text="DELETE https://us-central1-globhe-app.cloudfunctions.net/api/v1/orders/:id" />
          ) : (
            <CodeContainer text="DELETE https://us-central1-globhe-app.cloudfunctions.net/api/v2/orders/:id" />
          )}
          <ParagraphTitle text="Example Request" />
          {index === 0 ? (
            <CodeContainer text="DELETE https://us-central1-globhe-app.cloudfunctions.net/api/v1/orders/123456789" />
          ) : (
            <CodeContainer text="DELETE https://us-central1-globhe-app.cloudfunctions.net/api/v2/orders/123456789" />
          )}
          <ParagraphTitle text="Example Response" />
          <JsonContainer text={deleteResponseJsonExample} />
        </Container>
      </div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GLOBHE&apos;s developers docs</title>
        <link rel="icon" href={favicon} />
      </Helmet>
    </main>
  );
};

export default IndexPage;
