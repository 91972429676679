import { Typography } from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";

const textHeader = {
  marginBottom: "20px",
};

const PageTitle = ({ text }) => {
  return (
    <Typography variant="h4" style={textHeader}>
      {text}
    </Typography>
  );
};

PageTitle.propTypes = {
  text: PropTypes.string.isRequired,
};
export default PageTitle;
